import utils from "../utils";
import API from "../api";
import generic from "./generic";

const Rescue_plan = {

    createRescuePlan(user, suppliers, products, test_run, cb) {
        let data = {
            plan_user: user,
            suppliers: suppliers,
            products: products,
            test_run: test_run
        };
        API.createEntityRequest('rescue_plan', data, (data) => {
            generic.refreshEntity('rescue_plan', data.id, cb);
        }, (err) => {
            return cb(err);
        })
    },

    deleteRescuePlan(rescue_plan_id, cb) {
        API.deleteEntityRequest('rescue_plan', rescue_plan_id, (data) => {
            cb();
        }, (err) => {
            return cb(err);
        })
    }

}

export default Rescue_plan;

window.Product = Rescue_plan;