import React from "react";

import PanelHandler from "../global/PanelHandler";

import CreatePlanView from "./CreatePlanView";
import RescuePlanView from "./RescuePlanView";

export default class extends React.Component {

    state = {selectedView: 'create_plan'}

    render(){
        return (
            <div id="admin-view" style={{textAlign: 'center'}}>
                <div style={{display: 'inline-block', padding: '50px 100px', backgroundColor: 'white', width: '80%'}}>
                    <PanelHandler color="blue"
                        panels={[
                            {
                                name: 'create_plan',
                                title: 'Création plan',
                                content: <CreatePlanView />
                            },
                            {
                                name: 'rescue_plan',
                                title: 'Plans en cours',
                                content: <RescuePlanView />
                            }
                        ]}
                    />
                </div>
            </div>
        )
    }

}