import React from "react";
import Button from "./Button";

export default class PanelHandler extends React.Component {

    constructor(props) {
        super(props);
        let selected, panels = props.panels;
        if(props.default_select){
            selected = props.default_select;
        }else if(panels && panels.length > 0){
            selected = panels[0].name;
        }
        this.state = {
            id: (props.id ? props.id : window.generateID()),
            selected: selected,
            color: props.color || "#9e9e9e"
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let selected = this.state.selected, panels = this.props.panels;
        for(let panel of panels){
            if(panel.name === selected){
                return;
            }
        }
        this.setState({selected: panels.length > 0 ? panels[0].name : undefined})
    }

    onPanelSelectClick = (panel) => {
        if(this.props.globalSelectedPanelVar){
            window[this.props.globalSelectedPanelVar] = panel;
        }
        this.setState({ selected: panel }, () => {
            if(this.props.onPanelSelect){
                this.props.onPanelSelect(panel);
            }
        });
    };

    getButtonSize = () => {
        let panels = this.props.panels;
        if(this.props.buttonMaxSize){
            return Math.min(Math.round(12 / (panels.length || 1)), this.props.buttonMaxSize);
        }else{
            return Math.round(12 / (panels.length || 1));
        }
    };

    renderButton = (key, title) => {
        const color = this.props.color, selected = this.state.selected, panels = this.props.panels;
        return <div className={"col no-padding s" + this.getButtonSize()}>
            <Button id={key} text={title} key={key + "-select-panel-button"}
                    style={{
                        padding: "0",
                        width: "100%",
                        color: selected === key ? "white" : "#9e9e9e",
                        backgroundColor: selected === key ? color : "white"
                    }}
                    onClick={this.onPanelSelectClick.bind(null, key)}/>
        </div>;
    };

    renderPanel = (key, content) => {
        const selected = this.state.selected;
        return <div className={selected !== key ? "hide" : ""}>
            {content}
        </div>
    };

    render() {
        let panels = this.props.panels, column_count = panels.length * this.getButtonSize(), offset;
        if(panels.length > 1){
            offset = Math.round((12 - column_count) / 2);
        }
        return (
            <div>
                {panels.length > 1 ? <div className="row col s12 no-padding center-align">
                    {offset > 0 ? <div className={"col s" + offset}>&nbsp;</div> : undefined}
                    {panels.map(panel => {return this.renderButton(panel.name, panel.title)})}
                </div> : undefined}
                <div className={"row col s12 " + (this.props.containerClassName || "")} style={this.props.containerStyle}>
                    {panels.map(panel => {return this.renderPanel(panel.name, panel.content)})}
                </div>
            </div>
        )
    }

}