import utils from "../utils";
import _ from "lodash";

const default_file_supplier_map = {
    ref: {target: "", mandatory: true},
    name: {target: "", mandatory: true},
    address: {target: "", processing: utils.parseXlsLineBreak},
    email: {target: ""},
    fax: {target: ""},
    siret: {target: ""},
    client_ref: {target: ""},
    'contact.name': {target: ''},
    'contact.fax': {target: ""},
    'contact.phone': {target: ""},
    'contact.email': {target: ""},
    'contact.number': {target: ""}
}

const Supplier = {

    formatSupplierData: (supplier_data, supplier_map) => {
        let map = _.cloneDeep(default_file_supplier_map);
        for(let target in supplier_map){
            if(supplier_map[target] && supplier_map[target] !== ""){
                map[target].target = supplier_map[target];
            }
        }
        return utils.formatFileData(supplier_data, map);
    }

}

export default Supplier;

window.Supplier = Supplier;