import React from "react";
import InputField from "./global/InputField";
import Button from "./global/Button";
import User from "../model/user";
import config from "../config";

export default class extends React.Component {

    state = { username: '', password: '', error: false };

    onUsernameChange = (value) => {
        this.setState({username: value, error: false});
    };

    onPasswordChange = (value) => {
        this.setState({password: value, error: false});
    };

    onSignin = (username, password) => {
        console.log("Signin with username: " + username + " and password: " + password);
        User.signInWithCredentials(username, password, (err) => {
            if(err){
                this.setState({error: true});
            }else{
                this.props.onSigninSuccess();
            }
        });
    };

    render(){
        let mobile = config.MOBILE_DISPLAY;
        return this.props.show ? (
            <div id="signin-view" >
                <div style={{paddingTop: mobile ? '100px' : '200px', textAlign: 'center'}}>
                    <div style={{display: 'inline-block', border: '1px solid #e0e0e0', borderRadius: '20px', padding: '50px', backgroundColor: 'white'}}>
                        <div>
                            <InputField label="Utilisateur" onChange={this.onUsernameChange} />
                        </div>
                        <div>
                            <InputField label="Mot de passe" type="password" onChange={this.onPasswordChange} />
                        </div>
                        <div>
                            <Button className="theme-btn" text="Se connecter" onClick={this.onSignin.bind(null, this.state.username, this.state.password)} />
                        </div>
                        {this.state.error ? <div style={{color: 'red', marginTop: '10px'}}>Erreur d'authentification</div> : undefined}
                    </div>
                </div>
            </div>
        ): undefined;
    }

}