import React from "react";

import User from "../model/user";

import AdminView from "./AdminView/AdminView";
import UserView from "./UserView/UserView";

export default class extends React.Component {

    render(){
        return this.props.show ? <div id="main-view" style={{textAlign: 'center'}}>
            {
                User.isAdmin() ? <AdminView /> : <UserView />
            }
        </div> : undefined;
    }

}