import React  from 'react';
import * as $ from 'jquery';

import Modal from "../global/Modal";
import Button from "../global/Button";

window.alertPopup = (title, info, undismissible, close_cb) => {
    // before we can display this, fallback to native alert dialog
    alert(title + '\n' + info);
};

export default class AlertPopup extends React.Component {
    state = { openModal: null, closeModal: null };

    componentDidMount() {
        window.alertPopup = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (title, info, footer, undismissible = false, close_cb = function() {}) => {
        this.title = title;
        this.info = info;
        this.footer = footer;
        this.setState({ undismissible: undismissible, close_cb: close_cb }, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
        window.closeAlertPopup = close;
    };

    afterOpen = () => {
        $(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    title = null;
    info = null;
    footer = null;

    render() {
        const content = <div id="alert-popup-content">
            <h4>{this.title}</h4>
            <p>{this.info}</p>
            <div className="modal-footer">
                {this.footer}
                <Button text={"Fermer"} className='' color="red" onClick={this.state.closeModal} large={true}
                        style={{fontSize: "20px", borderRadius: "18px"}}/>
            </div>
        </div>;
        return (
            <Modal id="alert-popup"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.state.close_cb}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
