import * as $ from'jquery';
import _ from 'lodash';
import User from "./model/user";

function getAuthorization(options){
    if(User.access_token){
        return 'Bearer ' + User.access_token;
    }
    return '';
}

function requestAPI(options, successcb, errorcb, completecb) {

    if(!options.url)
        return;
    if(options.loading)
        window.showLoadingLayer();
    const headers = options.headers ? _.cloneDeep(options.headers) : {};
    headers.Authorization = getAuthorization(options);

    $.ajax({
        url: options.url,
        type: options.type ? options.type : "GET",
        contentType: options.data ? "application/json" : undefined,
        data: options.data ? JSON.stringify(options.data) : undefined,
        headers: headers,
        success: function(data, status, jqXHR) {
            if(successcb)
                successcb(data);
        },
        error: function(jqXHR, status, err) {
            console.log("error request");
            console.log(err);
            if(errorcb) {
                errorcb({ message: (jqXHR.responseJSON ? jqXHR.responseJSON.error : undefined), code: jqXHR.status });
            }
        },
        complete: function(jqXHR, status) {
            if(completecb)
                completecb();
            if(options.loading)
                window.hideLoadingLayer();
        }
    });
}

const API = {

    signInWithCredentialsRequest: (username, password, successcb , errorcb) => {
        let url = '/api/signin';
        let data = {username: username, password: password};
        requestAPI({type: 'POST', url: url, data: data, loading: true}, successcb, errorcb);
    },

    getEntityRequest: (entity_type, id, successcb, errorcb) => {
        let url = '/api/' + entity_type + 's/' + id;
        requestAPI({type: 'GET', url: url, loading: true}, successcb, errorcb);
    },

    getEntitiesRequest: (entity_type, successcb, errorcb, options = {}) => {
        let url = '/api/' + entity_type + 's', query = [];
        if(options.modified_since){
            query.push('modified_since=' + options.modified_since);
        }
        if(options.without){
            query.push('without=' + options.without.join(','));
        }
        if(query.length > 0){
            url += '?' + query.join('&');
        }
        requestAPI({type: 'GET', url: url, loading: true}, successcb, errorcb);
    },

    getOwnedEntities: (entity_name, successcb, errorcb) => {
        let url = '/api/' + entity_name + 's/owned';
        requestAPI({type: 'GET', url: url, loading: true, access_token: true}, successcb, errorcb);
    },

    createEntityRequest: (entity_type, data, successcb, errorcb) => {
        let url = '/api/' + entity_type + 's';
        requestAPI({type: 'POST', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    updateEntityRequest: (entity_type, id, data, successcb, errorcb) => {
        let url = '/api/' + entity_type + 's/' + id;
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    deleteEntityRequest: (entity_type, id, successcb, errorcb) => {
        let url = '/api/' + entity_type + 's/' + id;
        requestAPI({type: 'DELETE', url: url, loading: true, access_token: true}, successcb, errorcb);
    },

    sendOrderEmail: (order_id, data, successcb, errorcb) => {
        let url = '/api/orders/' + order_id + '/send_email';
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    }

};

export default API;