import React from "react";

/**
 * Props : --> id : id of the component
 *         --> label : text to display next to the checkbox
 *         --> onClick : called when checkbox is checked or unchecked
 *         --> className
 *         --> checked : externally control state
 *         --> labelColor
 */
export default class extends React.Component {
    state = {
        id: (this.props.id ? this.props.id : window.generateID()),
        checked: !!this.props.checked
    };

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.checked !== prevProps.checked) {
            // enforce new value
            this.setState({ checked: this.props.checked })
        }
    }

    onChange = (event) => {
        if (this.props.disabled)
            return;
        if(this.props.checked != null) {
            if(this.props.onClick)
                this.props.onClick(!this.state.checked);
        } else {
            this.setState({ checked: !this.state.checked }, () => {
                if(this.props.onClick)
                    this.props.onClick(this.state.checked);
            });
        }
    };

    render() {
        const labelStyle = {};
        if(this.props.labelColor)
            labelStyle.color = this.props.labelColor;
        if (this.props.disabled)
            labelStyle.color = "gray";
        return (
            <div className={"check-box " + (this.props.className ? this.props.className : "")}>
                <label style={labelStyle}>
                    <input id={this.state.id} type={this.props.radio ? "radio" : "checkbox"} className="filled-in"
                           disabled={this.props.disabled ? "disabled" : null}
                           checked={this.state.checked} onChange={this.onChange}/>
                    <span>{this.props.label}</span>
                </label>
            </div>
        )
    }
}
