import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import React          from "react";
import _                           from 'lodash';
import XlsExport                   from 'xlsexport/xls-export';

import CheckBox      from "./CheckBox";
import DropDownPanel from "./DropDownPanel";
import Button        from "./Button";

const COLUMN_SEPARATOR = 'COLSEP;';
export default class AgGrid extends React.Component {
    state = {
        id: this.props.id || Date.now(),
        selectedColumns: [...this.props.columnDefs],
        gridApi: null,
        gridColumnApi: null
    }

    componentDidMount() {
        if(this.props.getExportToCSV)
            this.props.getExportToCSV(this.exportToCSV);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.columnDefs !== this.props.columnDefs) {
            this.setState({ selectedColumns: [...this.props.columnDefs] });
        }
    }

    onGridReady = (event) => {
        this.setState({
            gridApi: event.api,
            gridColumnApi: event.columnApi
        }, () => {
            if(this.props.gridOptions.onGridReady) {
                this.props.gridOptions.onGridReady(event);
            }
        })
    }

    getRowNodeId = (data) => {
        return data.id; // return the property you want set as the id.
    }

    exportToCSV = (fileName, fileType, language, processCellCallback = (params) => params.value) => {
        const csvAsString = this.state.gridApi.getDataAsCsv({
            columnSeparator: COLUMN_SEPARATOR,
            allColumns: false,
            processCellCallback
        });
        const csvDataAsArrayOfNewLines                 = csvAsString.split('\r\n'),
              headerLine                               = csvDataAsArrayOfNewLines.shift(),
              csvAsArrayOfStringLinesWithoutHeaderLine = csvDataAsArrayOfNewLines,
              headerLineAsArray                        = headerLine.split(COLUMN_SEPARATOR),
              csvAsJSON                                = [];
        let currentLineJSON = {};
        for(const csvAsStringLine of csvAsArrayOfStringLinesWithoutHeaderLine) {
            const csvCellDataArray = csvAsStringLine.split(COLUMN_SEPARATOR);
            let headerIndex = 0;
            while(headerIndex < headerLineAsArray.length) {
                const headerValue = headerLineAsArray[headerIndex];
                let csvCellData = csvCellDataArray[headerIndex];
                if(fileType === 'xls') {
                    csvCellData = csvCellData.replace(/"/g, '');
                    csvCellData = csvCellData.replace(/\n/g, '<br/>');
                }
                let cellTitle = fileType === 'xls' ? headerValue.replace(/"/g, '') : headerValue;
                currentLineJSON[cellTitle] = csvCellData;
                headerIndex++;
            }
            csvAsJSON.push(currentLineJSON);
            currentLineJSON = {};
        }
        var xls = new XlsExport(csvAsJSON);
        if(fileType === 'xls') {
            xls.exportToXLS(fileName + '.xls');
        } else {
            xls.exportToCSV(fileName + '.csv');
        }
    };

    render() {
        const columns = this.state.selectedColumns;
        return <div id={this.state.id} className="ag-theme-alpine"
                    style={{ height: this.props.height || "100%", width: "100%" }}>
            <AgGridReact
                id={this.state.id}
                rowData={this.props.rowData}
                gridOptions={{
                    onFirstDataRendered: params => {
                        params.columnApi.autoSizeAllColumns();
                    },
                    ...this.props.gridOptions,
                    onGridReady: this.onGridReady,
                    rowBuffer: this.state.rowBuffer,
                    getRowNodeId: this.getRowNodeId
                }}
                frameworkComponents={{ ReactDefaultRenderer: ReactDefaultRenderer }}
                immutableData={this.props.immutableData || false}
                getRowNodeId={this.props.getRowNodeId || (row => row.id)}>
                {columns.map(column => (<AgGridColumn {...column} key={column.field}/>))}
            </AgGridReact>
        </div>
    }
}

class ReactDefaultRenderer extends React.Component {
    render() {
        return <div style={{ whiteSpace: "pre" }}>{this.props.value}</div>;
    }
}

export class ColumnsSelector extends React.Component {
    onColumnSelectionChange = column => {
        const newState = !this.isDisplayedColumn(column);
        this.props.columnApi.setColumnVisible(column.colId, newState);
        this.props.onColumnSelectionChange([column.colId], newState);
        this.forceUpdate();
    }

    setAllSelected = (selected) => {
        const allColumns = this.props.columnApi.getAllColumns();
        this.props.columnApi.setColumnsVisible(_.map(allColumns, 'colId'), selected);
        if(this.props.onColumnSelectionChange)
            this.props.onColumnSelectionChange([allColumns], selected);
        this.forceUpdate();
    }

    isDisplayedColumn = column => {
        const displayedColumnsIds = _.map(this.props.columnApi.getAllDisplayedColumns(), 'colId');
        return displayedColumnsIds.indexOf(column.colId) > -1;
    }

    render() {
        if(!this.props.columnApi) {
            return null;
        }
        const filters    = [],
              allColumns = this.props.columnApi.getAllColumns();
        let index = 0;
        for(let column of allColumns) {
            filters.push(<CheckBox key={column.colId} label={column.colDef.headerName}
                                   className={"data-table-filter " + (index === allColumns.length - 1 ? "no-border" : "")}
                                   checked={this.isDisplayedColumn(column)}
                                   onClick={this.onColumnSelectionChange.bind(null, column)}/>)
            index++;
        }
        return <div style={{ display: "inline-block", ...this.props.style }}
                    className={this.props.className}>
            <DropDownPanel content={
                <span>
                    <div className={"row bm"}>
                        <Button className={"lm"} text={window.appText.selectAll}
                                onClick={this.setAllSelected.bind(this, true)}/>
                        <Button className={"lm"} text={window.appText.unSelectAll}
                                onClick={this.setAllSelected.bind(this, false)}/>
                    </div>
                    <div className={"row"}>
                        {filters}
                    </div>
                </span>
            } header={window.appText.columns} style={{ maxWidth: "none" }} contentStyle={this.props.contentPanelStyle}/>
        </div>
    }
}
