import React            from 'react';
import LoadingAnimation from './global/LoadingAnimation.js';

window.showLoadingLayer = function() {};
window.hideLoadingLayer = function() {};

export default class extends React.Component {
    state = { show: false };

    componentDidMount() {
        window.showLoadingLayer = () => this.setState({ show: true });
        window.hideLoadingLayer = () => this.setState({ show: false });
    }

    render() {
        return (
            <div id="loading-layer"
                    className={["loadingLayerWrapper", "valign-wrapper", this.state.show ? "" : "hide"].join(" ")}>
                <div className={["loadingBox", "z-depth-1", "valign"].join(" ")} style={{ margin: "auto" }}>
                    <LoadingAnimation type="circular" color={"yellow"} size="big"/>
                </div>
            </div>
        )
    }
}

