import _ from "lodash";

const utils = {

    getFileExtension: function(file) {
        return file.name.split('.').pop().toLowerCase();
    },

    parseXlsxFile: function(file, callback) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: 'binary'});
            const sheet_name_list = workbook.SheetNames;
            const sheet = workbook.Sheets[sheet_name_list[0]];
            const json = XLSX.utils.sheet_to_json(sheet, {raw: true});
            callback(json);
        };
        reader.readAsBinaryString(file);
    },

    processNumber: function(value) {
        return {result: parseFloat(typeof value === 'string' ? value.replaceAll(',', '.') : value)};
    },

    processPhoneNumber: (data) => {
        let result = data.replaceAll(" ", "").replaceAll(".", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", ""),
            error = false;
        if(result.length !== 10){
            error = true;
        }
        return {result, error};
    },

    formatFileData: (file_data, file_map) => {
        let items = [], errors = [];
        for(let data of file_data){
            let supplier = {}, error_list = [];
            for(let key in file_map){
                let value = data[file_map[key].target];
                if(value && value !== "" && value !== " "){
                    let target = file_map[key].target;
                    if(file_map[key].processing){
                        let result = file_map[key].processing(value);
                        if(result.error){
                            error_list.push({field: target, error: "invalid"});
                        }
                        value = result.result;
                    }
                    _.set(supplier, key, value);
                }else if(file_map[key].mandatory){
                    error_list.push({field: file_map[key].target, error: "missing"});
                }
            }
            if(error_list.length > 0){
                errors.push({data, error_list});
            }else{
                items.push(supplier);
            }
        }
        return { items, errors };
    },

    parseXlsLineBreak: (data) => {
        return {result: data.replaceAll('&#10;', '\n')};
    },

    parseLineBreakAsArray: (data) => {
        return {result: utils.parseXlsLineBreak(data).result.split('\n')};
    },

    arrayBufferToBase64: (buffer) => {
        let binary = '';
        let bytes = new Uint8Array( buffer );
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

};

String.prototype.upperFirstLetter = function() {
    let target = this, delimiter;
    if(target.indexOf('-') > -1){
        delimiter = '-';
    }else if(target.indexOf('_') > -1){
        delimiter = '_';
    }
    if(!delimiter){
        return target.replace(target[0], target[0].toUpperCase());
    }else{
        let split = target.split(delimiter);
        for(let i = 0; i < split.length; i++){
            split[i] = split[i].upperFirstLetter();
        }
        return split.join('');
    }
};

String.prototype.replaceAll = function(search, replacement) {
    let target = this;
    return target.split(search).join(replacement);
}

if (typeof(Number.prototype.toRad) === "undefined") {
    Number.prototype.toRad = function () {
        return this * Math.PI / 180;
    }
}

_.asyncMap = (list, func, callback, options = {}) => {
    if(list.length === 0){
        return callback();
    }
    if(options.keep_order){
        let index = 0, process_next = () => {
            let item = list[index];
            func(item, () => {
                index++;
                if(index >= list.length){
                    return callback();
                }else{
                    return process_next();
                }
            });
        };
        process_next();
    }else{
        let processed_count = 0, process_cb = () => {
            processed_count++;
            if(processed_count === list.length){
                return callback();
            }
        };
        for(let item of list){
            func(item, process_cb);
        }
    }
};
export default utils;

window.utils = utils;