import _ from 'lodash';
import Config from '../config';

import utils from "../utils";
import User from "./user";

import generic from "./generic";

let entity_load_list = ['rescue_plan', 'supplier', 'product', 'order']

const model = {

    rescue_plan: {},
    supplier: {},
    product: {},
    order: {},

    initialize: (cb = () => {}) => {
        User.initialize(model, generic);
        return cb();
    },

    loadData: (cb = () => {}) => {
        let load_fn = User.isAdmin() ? generic.loadEntities : generic.loadOwnedEntities;
        _.asyncMap(entity_load_list, (entity_type, process_cb) => {
            load_fn(entity_type, process_cb);
        }, (err) => {
            if(err){
                return cb(err);
            }
            User.loadUserData(() => {
                window.refreshApp();
                return cb();
            })
        }, {keep_order: true});
    }


};

generic.initialize(model);

export default model;

window.model = model;