function isDesktopDevice(){
    let agent = navigator.userAgent;
    return agent.indexOf('Linux') > -1 || agent.indexOf('Windows') > -1 || agent.indexOf('Mac') > -1;
}

const config = {

    MOBILE_RES_THRESHOLD: isDesktopDevice() ? 750 : 1054,
    MOBILE_DISPLAY: window.innerWidth < (isDesktopDevice() ? 750 : 1054),

};

export default config;