import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import DataTable from "../global/DataTable";
import CheckBox from "../global/CheckBox";
import ClientDisplay from "../Common/ClientDisplay";

import generic from "../../model/generic";
import config from "../../config";
import User from "../../model/user";
import _ from "lodash";

let order_products_columns = config.MOBILE_DISPLAY ? [
    {header: "Code", target: "product.supplier_ref"},
    {header: "Nom", target: "product.name"},
    {header: "Qté", compute: getQuantityInput},
    {header: "TTC", compute: computeTTC, suffix: ' €'},
    {header: "Comm.", compute: getCommentDisplay},
    {header: "", compute: getOrderProductsActions}
] : [
    {header: "Code Produit", target: "product.supplier_ref"},
    {header: "Nom Produit", target: "product.name"},
    {header: "UCD", target: "product.ucd_code"},
    {header: "CIP", target: "product.cip_code"},
    {header: "Quantité", compute: getQuantityInput},
    {header: "Prix Unit. HT", target: "product.price_ht", suffix: " €"},
    {header: "TVA", target: "product.vat", suffix: ' %'},
    {header: "Prix TTC", compute: computeTTC, suffix: ' €'},
    {header: "Commentaire", compute: getCommentDisplay},
    {header: "", compute: getOrderProductsActions}
];

let product_add_columns = config.MOBILE_DISPLAY ? [
    {header: "Nom", compute: getNameInput},
    {header: "Qté", compute: getNewQuantityInput},
    {header: "TTC", compute: computeTTC, suffix: ' €'},
    {header: "Comm.", compute: getCommentInput},
    {header: "", compute: getProductAddActions}
] : [
    {header: "Code Produit", target: "product.supplier_ref"},
    {header: "Nom Produit", compute: getNameInput},
    {header: "UCD", target: "product.ucd_code"},
    {header: "CIP", target: "product.cip_code"},
    {header: "Quantité", compute: getNewQuantityInput},
    {header: "Prix Unit. HT", target: "product.price_ht", suffix: " €"},
    {header: "TVA", target: "product.vat", suffix: ' %'},
    {header: "Prix TTC", compute: computeTTC, suffix: ' €'},
    {header: "Commentaire", compute: getCommentInput},
    {header: "", compute: getProductAddActions}
];

function getCommentDisplay(data){
    let comment = data.order_product.comment;
    return <span style={{maxWidth: config.MOBILE_DISPLAY ? "80px": "120px", overflow: 'auto', display: 'inline-block'}}>{comment}</span>
}

function computeTTC(data){
    let ht = data.product.price_ht, vat = data.product.vat, quantity = _.get(data, 'order_product.quantity', _.get(data, 'component.state.product_quantity_input', 0));
    if(ht !== undefined && vat !== undefined){
        return _.round(quantity *ht * (1 + vat / 100), 2);
    }else{
        return 0;
    }
}

function getNewQuantityInput(data){
    let _this = data.component;
    return <InputField label="Quantité" type={"number"} step={1} min={1} style={{ width: config.MOBILE_DISPLAY ? "30px": "80px", margin: 0 }}
                value={_this.state.product_quantity_input} onChange={_this.onNewProductQuantityChange} />
}

function getQuantityInput(data, index){
    let _this = data.component, products = _this.state.products, quantity = products[index].quantity;
    return <InputField type={"number"} step={1} min={1} style={{ width: config.MOBILE_DISPLAY ? "30px": "80px", margin: 0 }}
                       value={quantity} onChange={_this.onProductQuantityChange.bind(null, index)} />
}

function getNameInput(data){
    let _this = data.component;
    return <InputField label="Nom du produit" value={_this.state.product_name_input} onChange={_this.onProductNameChange}
                autoCompleteStyle={{ position: "fixed", top: "initial" }}
                autoComplete={_this.getProductNames()} autoCompleteSelect={_this.onProductNameChange}
                autoCompleteSearch={true} autoCompleteMaxHeight={config.MOBILE_DISPLAY ? 200 : 250}
                autoCompleteWidth={config.MOBILE_DISPLAY ? "300px" : "400px"}
    />
}

function getCommentInput(data){
    let _this = data.component;
    return <InputField value={_this.state.product_comment_input} onChange={_this.onProductCommentChange} style={config.MOBILE_DISPLAY ? {width: '30px'} : {}} />
}

function getProductAddActions(data){
    let product_match = Object.keys(data.product).length > 0, _this = data.component;
    return <div>
        <i className={"material-icons " + (!product_match ? "grey-text" : "green-text clickable")}
           onClick={!product_match ? undefined : _this.onValidateProduct} style={form_icon_btc_style}>check</i>
        <i className="material-icons red-text clickable" onClick={_this.onCancelProduct} style={form_icon_btc_style}>close</i>
    </div>
}

function getOrderProductsActions(data){
    let _this = data.component;
    return <div>
        <i className="material-icons red-text clickable" onClick={_this.onRemoveProduct.bind(null, data.index)}>clear</i>
    </div>
}

export default class ModalModel extends React.Component {

    state = { action: 'add', order_id: null, opened: false, openModal: null, closeModal: null, supplier_name_input: "", supplier: null, products: [],
        adding_product: false, product_name_input: "", product_quantity_input: 1, product_comment_input: "", comment_input: "" };

    componentDidMount() {
        window.openOrderForm = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = function() {}) => {
        options = options || {};
        let state = {action: options.action || 'add', opened: true, undismissible: true, close_cb: close_cb, supplier_name_input: "", supplier: null, products: [],
            adding_product: false, product_name_input: "", product_quantity_input: 1, product_comment_input: "", comment_input: "", on_send: options.on_send };
        if(options.action === 'edit'){
            let order = generic.getEntity('order', options.order_id);
            state.order_id = order.id;
            let products = [];
            for(let order_product of order.products){
                let product = generic.getEntity("product", order_product.id);
                products.push({id: product.id, code: product.supplier_ref, name: product.name, quantity: order_product.count, comment: order_product.comment});
            }
            state.supplier = generic.getEntity("supplier", order.supplier);
            state.products = products;
            state.comment_input = order.comment;
        }
        this.setState(state, this.state.openModal);

    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    getSupplierNames = () => {
        let result = [], suppliers = generic.getEntities("supplier", true);
        for(let supplier of suppliers){
            result.push({val: supplier.name});
        }
        return result;
    };

    getProductNames = () => {
        let result = [], supplier = this.state.supplier, products = generic.getEntitiesFromProperty("product", "supplier", supplier.ref);
        for(let product of products){
            result.push({val: product.name});
        }
        return result;
    };

    onSupplierNameChange = (value) => {
        this.setState({supplier_name_input: value});
    };

    onValidateSupplier = () => {
        let name = this.state.supplier_name_input;
        this.setState({supplier: generic.getEntityFromProperty("supplier", "name", name)});
    };

    onAddProduct = () => {
        this.setState({adding_product: true, product_name_input: "", product_quantity_input: 1, product_comment_input: ""});
    };

    onAddAllProducts = () => {
        let supplier = this.state.supplier, order_products = this.state.products.slice(),
            products = generic.getEntitiesFromProperty("product", "supplier", supplier.ref);
        for(let product of products){
            order_products.push({id: product.id, code: product.supplier_ref, name: product.name, quantity: 0, comment: ""});
        }
        this.setState({products: order_products});
    }

    onRemoveProduct = (index) => {
        let products = this.state.products.slice();
        products.splice(index, 1);
        this.setState({products: products});
    };

    onProductNameChange = (value) => {
        this.setState({product_name_input: value});
    };

    onProductQuantityChange = (index, value) => {
        let products = this.state.products.slice();
        products[index].quantity = value;
        this.setState({products: products});
    }

    onNewProductQuantityChange = (value) => {
        this.setState({product_quantity_input: value});
    };

    onProductCommentChange = (value) => {
        this.setState({product_comment_input: value});
    }

    onCancelProduct = () => {
        this.setState({adding_product: false});
    };

    onValidateProduct = () => {
        let name = this.state.product_name_input, quantity = this.state.product_quantity_input,
            comment = this.state.product_comment_input, products = this.state.products.slice();
        let product = generic.getEntityFromProperty("product", "name", name);
        if(product){
            products.push({id: product.id, code: product.supplier_ref, name: product.name, quantity: quantity, comment: comment});
            this.setState({products: products, adding_product: false});
        }
    };

    onCommentChange = (value) => {
        this.setState({comment_input: value});
    };

    onSubmitAndSend = () => {
        this.onSubmit((order_id) => {
            if(this.state.on_send){
                this.state.on_send(order_id);
            }
        })
    };

    onSubmit = (cb = () => {}) => {
        let supplier = this.state.supplier, products = this.state.products, comment = this.state.comment_input, order_products = [];
        for(let product of products){
            if(product.quantity > 0){
                order_products.push({id: product.id, count: product.quantity, comment: product.comment});
            }
        }
        if(this.state.action === 'add'){
            let order = {supplier: supplier.id, products: order_products, comment: comment};
            generic.createEntity("order", order, (err, entity_id) => {
                if(err){
                    window.alertPopup('Erreur', 'Une erreur est survenue lors de la création du bon de commande !');
                }else{
                    this.state.closeModal();
                    if(typeof cb === 'function'){
                        return cb(entity_id);
                    }
                }
            }, {refresh_after_creation: true});
        }else{
            let data = {products: order_products, comment: comment};
            generic.updateEntity("order", this.state.order_id, data, (err) => {
                if(err){
                    window.alertPopup('Erreur', 'Une erreur est survenue lors de la modification du bon de commande !');
                }else{
                    this.state.closeModal();
                    if(typeof cb === 'function'){
                        return cb(this.state.order_id);
                    }
                }
            });
        }
    };

    getProductAddDisplay = () => {
        let headers = [], row = [], product_name = this.state.product_name_input;
        let product = generic.getEntityFromProperty("product", "name", product_name) || {},
            data = {component: this, product: product}
        for(let column of product_add_columns){
            headers.push(column.header);
            let value = _.get(data, column.target, (column.compute && column.compute(data)) || "");
            if(!data.compute && column.suffix){
                value += column.suffix;
            }
            row.push(value);
        }
        return <div>
            <h5>Ajout produit</h5>
            <DataTable headers={{headers}} rows={[{data: row}]} />
        </div>
    };

    getOrderProductsDisplay = () => {
        let headers = [], rows = [], products = this.state.products, total_ttc = 0;
        for(let column of order_products_columns){
            headers.push(column.header);
        }
        for(let i = 0; i < products.length; i++){
            let order_product = products[i], product = generic.getEntityFromProperty("product", "name", order_product.name) || {}
            let row = [], data = {component: this, product: product, order_product: order_product, index: i};
            for(let column of order_products_columns){
                let value = _.get(data, column.target, (column.compute && column.compute(data, i)) || "");
                if(!data.compute && column.suffix){
                    value += column.suffix;
                }
                row.push(value);
            }
            rows.push({data: row});
            total_ttc += computeTTC(data);
        }
        return <div>
            <DataTable headers={{headers}} rows={rows} />
            <div className="bold">{"Total TTC : " + total_ttc + " €"}</div>
        </div>
    }

    render() {
        let disabled = false, content, supplier = this.state.supplier;
        if(this.state.opened){
            let form_content, supplier_name = this.state.supplier_name_input, action = this.state.action, order = generic.getEntity("order", this.state.order_id);
            if(supplier){
                let products = this.state.products, adding_product = this.state.adding_product, product_rows = [], at_least_one_product = false,
                product_headers = ["Code produit", "Nom", config.MOBILE_DISPLAY ? "Qté" : "Quantité", "TTC"],
                    product_match = false, product_match_rows = [];
                for(let product of products){
                    if(product.quantity > 0){
                        at_least_one_product = true;
                    }
                }
                if(!at_least_one_product){
                    disabled = true;
                }
                for(let i = 0; i < products.length; i++){
                    let product = products[i];
                    product_rows.push({data: [product.code, product.name, product.quantity,
                            <i className="material-icons red-text clickable" onClick={this.onRemoveProduct.bind(null, i)}>clear</i>]});
                }
                if(adding_product){
                    let product_name = this.state.product_name_input;
                    product_match = generic.getEntityFromProperty("product", "name", product_name);
                }
                form_content = <div className="row " style={{marginTop: '30px'}}>
                    <div className="col s12 bm">
                        {action === 'edit' ? <h5>{"Bon de commande : " + order.order_number}</h5> : null}
                        {action === 'edit' ? <h5>{"Date : " + order.date}</h5> : null}
                        <h5>{"Fournisseur : " + supplier.name}</h5>
                    </div>
                    <div className="col s12" style={{marginTop: '20px'}}>
                        <InputField label="Commentaires" value={this.state.comment_input} onChange={this.onCommentChange} textarea={true} />
                    </div>
                    {products.length > 0 ? <div className="col s12">
                        {this.getOrderProductsDisplay()}
                    </div> : null}
                    {products.length > 0 && false ? <div className="col s12">
                        <DataTable headers={{headers: product_headers}} rows={product_rows} />
                    </div> : undefined}
                    {adding_product ? <div className="col s12" style={{paddingBottom: config.MOBILE_DISPLAY ? "100px" : "150px"}}>
                        {this.getProductAddDisplay()}
                    </div> : undefined}
                    {!adding_product ? <div className="col s12 center-align" style={{marginTop: config.MOBILE_DISPLAY ? '15px' : '30px'}}>
                        <Button text="Ajouter produit" className='theme-btn' onClick={this.onAddProduct} />
                        <Button text="Ajouter tous les produits" className='theme-btn lm' onClick={this.onAddAllProducts} />
                    </div> : undefined}
                </div>
            }else{
                disabled = !generic.getEntityFromProperty("supplier", "name", supplier_name);
                form_content = <div className="row " style={{marginTop: '50px', paddingBottom: config.MOBILE_DISPLAY ? "50px" : "150px"}}>
                    <div className="col l8 offset-sl s12">
                        <InputField label="Nom du fournisseur" type="text" onChange={this.onSupplierNameChange}
                                    autoCompleteStyle={{ position: "fixed", top: "initial" }}
                                    autoComplete={this.getSupplierNames()} autoCompleteSelect={this.onSupplierNameChange}
                                    autoCompleteSearch={true} autoCompleteMaxHeight={config.MOBILE_DISPLAY ? 200 : 300}
                        />
                    </div>
                </div>;
            }
            content = <div id="order-form-content" className="">
                <h3 style={{margin: 0}}>{this.state.action === 'add' ? "Création bon de commande" : "Edition bon de commande"}</h3>
                <div style={{marginTop: '20px'}}>
                    <ClientDisplay user={User.getCurrentUserData()} />
                </div>
                {form_content}
                <div className="modal-footer">
                    {supplier && <Button text={this.state.action === 'add' ? 'Créer & Envoyer' : 'Sauvegarder & Envoyer'} onClick={this.onSubmitAndSend} className='theme-btn' disabled={disabled || !supplier.email} style={{fontSize: "20px"}} />}
                    {supplier && <Button text={this.state.action === 'add' ? 'Créer' : 'Sauvegarder'} onClick={this.onSubmit} className='theme-btn lm' disabled={disabled} style={{fontSize: "20px", marginLeft: '20px'}} />}
                    {!supplier && <Button text={'Suivant'} onClick={this.onValidateSupplier} className='theme-btn' disabled={disabled} style={{fontSize: "20px"}} />}
                    <Button text={"Annuler"} color="red" onClick={this.state.closeModal} large={true} style={{fontSize: "20px", marginLeft: '20px', borderRadius: "18px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="order-form"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={true}
            />
        )
    }
};

const form_icon_btc_style = {
    fontSize: '36px',
    position: 'relative',
    top :'12px'
}
