import React from "react";

import logo from '../images/logo.png';

export default class extends React.Component {

  render() {
    return <div id="navbar" style={{backgroundColor: "#081131", padding: "10px", position: 'relative'}}>
      <div style={{display: 'inline-block', marginLeft: "20px"}}>
        <img className="navbar-logo" src={logo}  />
        <span id="navbar-title" >
            <span style={{color: "#82d1d9"}}>Hospi</span>
            <span>Secure</span></span>
      </div>
    </div>
  }

}

