import api from "../api";

const Order = {

    sendOrderEmail: (order_id, data, successcb, errorcb) => {
        api.sendOrderEmail(order_id, data, successcb, errorcb);
    }

}

export default Order;