import React from "react";

import AgGrid from "../global/AgGrid";

import generic from "../../model/generic";

export default class extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state && this.state.gridApi){
            this.state.gridColumnApi.autoSizeAllColumns();
            setTimeout(this.state.gridApi.sizeColumnsToFit, 200);
        }
    }

    onGridReady = (event) => {
        this.setState({ gridApi: event.api, gridColumnApi: event.columnApi });
    };

    getHeaders = () => {
        let headers = [
            {headerName: "Référence client", field: "client_ref"},
            {headerName: "Code fournisseur", field: "ref", colId: "ref"},
            {headerName: "Nom", field: "name", colId: "name", filter: 'agTextColumnFilter', floatingFilter: true},
            {headerName: "Adresse", field: "address", colId: "address"},
            {headerName: "E-mail", field: "email", colId: "email"},
            {headerName: "Fax", field: "fax", colId: "fax"},
            {headerName: "Siret", field: "siret", colId: "siret"},
            {headerName: "N° Point Commande", field: "contact.number", colId: "contact_number"},
            {headerName: "Nom Point Commande", field: "contact.name", colId: "contact_name"},
            {headerName: "Email Point Commande", field: "contact.email", colId: "contact_email"},
            {headerName: "Téléphone Point Commande", field: "contact.phone", colId: "contact_phone"},
            {headerName: "Fax Point Commande", field: "contact.fax", colId: "contact_fax"},
        ];
        return headers;
    };

    row_cache = null;

    getRows = () => {
        if(this.row_cache && this.row_cache.length > 0){
            return this.row_cache;
        }
        let suppliers = generic.getEntities("supplier", true), rows = [];
        for(let supplier of suppliers){
            rows.push(supplier);
        }
        this.row_cache = rows;
        return rows;
    };

    render(){

        return <div id="user-supplier-view" >
            <div style={{height: "500px", textAlign: 'left'}}>
                <AgGrid id={"users"}
                        columnDefs={this.getHeaders()}
                        rowData={this.getRows()} immutableData={true} getRowNodeId={row => row.id}
                        gridOptions={{
                            /*rowSelection: "single",
                            getRowClass: this.getRowClass,
                            onRowSelected: this.onRowSelectionChange,*/
                            onGridReady: this.onGridReady
                        }}/>
            </div>
        </div>
    }

}