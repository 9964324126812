import API from "../api";
import utils from "../utils";

const file_user_map = {
    "username": {target: "username", mandatory: true},
    "name": {target: "name", mandatory: true},
    "email": {target: "email"},
    "phone": {target: "phone"},
    "fax": {target: "fax"},
    "address": {target: "address", processing: utils.parseXlsLineBreak},
    "siret": {target: "siret"},
    "rpps": {target: "rpps"},
    "billing_contact.name": {target: 'billing_name'},
    "billing_contact.email": {target: 'billing_email'},
    "billing_contact.phone": {target: 'billing_phone'},
    "billing_contact.fax": {target: 'billing_fax'},
    "delivery_contact.name": {target: 'delivery_name'},
    "delivery_contact.email": {target: 'delivery_email'},
    "delivery_contact.phone": {target: 'delivery_phone'},
    "delivery_contact.fax": {target: 'delivery_fax'}
}

const User = {

    auth_user: null,

    user_data: null,

    access_token: null,

    model_ref: null,

    generic_ref: null,

    initialize: (model_ref, generic_ref) => {
        User.model_ref = model_ref;
        User.generic_ref = generic_ref;
        try{
            let cached_user = sessionStorage.getItem('current_user');
            if(cached_user){
                cached_user = JSON.parse(cached_user);
                if(cached_user.tokenExpiresOn > Date.now()){
                    User.auth_user = cached_user;
                    User.onSignin();
                    return true;
                }
            }
        }catch(e){
            console.log('Error while retrieving cached user');
            console.log(e);
        }
    },

    loadUserData: (cb) => {
        API.getEntityRequest("user", User.auth_user.id, (data) => {
            User.user_data = data.user;
            cb(null);
        }, (err) => {
            console.log('Error while loading user data ');
            cb(err);
        });
    },

    getCurrentUser: () => {
        return User.auth_user;
    },

    getCurrentUserData: () => {
        return User.user_data;
    },

    isAuthenticated: () => {
        return User.auth_user
    },

    isAdmin: () => {
        return User.auth_user && User.auth_user.isAdmin;
    },

    signInWithCredentials: (email, password, cb) => {
        API.signInWithCredentialsRequest(email, password, (data) => {
            User.auth_user = data.user;
            User.onSignin();
            return cb(null);
        }, (err) => {
            console.log('Error while signing in : ' + JSON.stringify(err));
            return cb(err);
        })
    },

    onSignin: () => {
        console.log('ON SIGNIN');
        sessionStorage.setItem('current_user', JSON.stringify(User.auth_user));
        User.access_token = User.auth_user.token;
        User.model_ref.loadData();
    },

    formatUserData: (user_data) => {
        return utils.formatFileData(user_data, file_user_map);
    }

};

export default User;

window.User = User;