import utils from "../utils";
import _ from "lodash";

const default_file_product_map = {
    ref: {target: "", mandatory: true},
    name: {target: "", mandatory: true},
    supplier: {target: "", mandatory: true},
    supplier_ref: {target: ""},
    price_ht: {target: "", processing: utils.processNumber},
    price_ttc: {target: "", processing: utils.processNumber},
    vat: {target: "", processing: utils.processNumber},
    ucd_code: {target: ""},
    cip_code: {target: ""},
    packing: {target: ""},
    market_number: {target: ""}
}

const Product = {

    formatProductData: (product_data, product_map) => {
        let map = _.cloneDeep(default_file_product_map);
        for(let target in product_map){
            if(product_map[target] && product_map[target] !== ""){
                map[target].target = product_map[target];
            }
        }
        return utils.formatFileData(product_data, map);
    }

}

export default Product;

window.Product = Product;