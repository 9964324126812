import React from "react";

import utils from "../../utils";
import Supplier from "../../model/supplier";
import generic from "../../model/generic";

import Button from "../global/Button";
import DataTable from "../global/DataTable";

export default class extends React.Component {

    componentDidMount() {
        generic.addEntityListener('rescue_plan', () => {this.forceUpdate()});
    }

    render() {
        let rescue_plans = generic.getEntities('rescue_plan', true), rows = [];
        for(let rescue_plan of rescue_plans){
            rows.push({data: [rescue_plan.name, rescue_plan.supplier_count, rescue_plan.product_count, rescue_plan.last_order_number, getDeleteButton(rescue_plan)]});
        }
        return <div>
            <h4>Plans de sauvegarde en cours</h4>
            <DataTable headers={{headers: ['Nom', 'Fournisseurs', 'Produits', 'Commandes', '']}} rows={rows} />
        </div>
    }

}

function getDeleteButton(rescue_plan){
    return <Button
        color={"red"}
        text="Supprimer"
        onClick={() => {
            if(confirm("Voulez-vous vraiment supprimer ce plan de sauvegarde ?")){
                generic.deleteEntity('rescue_plan', rescue_plan.id, (err) => {
                    if(err){
                        return alert(err);
                    }
                });
            }
        }}
    />
}