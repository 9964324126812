import React from "react";

import PanelHandler from "../global/PanelHandler";

import SupplierView from "./SupplierView";
import ProductView from "./ProductView";
import OrderView from "./OrderView";
import MobileOrderView from "./MobileOrderView";
import MediaQuery from "react-responsive";
import config from "../../config";


export default class extends React.Component {

    state = {selectedView: 'order'}

    onPanelSelect = (panel) => {
        this.setState({selectedView: panel})
    }

    render(){
        let selected = this.state.selectedView;
        return (
            <div id="user-view" style={{textAlign: 'center'}}>
                <MediaQuery minWidth={config.MOBILE_RES_THRESHOLD}>
                    <div style={{display: 'inline-block', padding: '50px 100px', backgroundColor: 'white', width: '80%'}}>
                        <PanelHandler color="#752eb6"
                                      onPanelSelect={this.onPanelSelect}
                                      panels={[
                                          {
                                              name: 'order',
                                              title: 'Bon de commandes',
                                              content: <OrderView show={selected === 'order'} />
                                          },
                                          {
                                              name: 'supplier',
                                              title: 'Fournisseurs',
                                              content: <SupplierView show={selected === 'supplier'} />
                                          },
                                          {
                                              name: 'product',
                                              title: 'Produits',
                                              content: <ProductView show={selected === 'product'} />
                                          }
                                      ]}
                        />
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={config.MOBILE_RES_THRESHOLD}>
                    <div style={{display: 'inline-block', padding: '10px 20px', backgroundColor: 'white', width: '90%'}}>
                        <MobileOrderView />
                    </div>
                </MediaQuery>
            </div>
        )
    }

}