import React from 'react';

import styles from "./DropDownPanel.module.scss";

/**
 * Props : --> id
 *         --> header
 *         --> content
 *         --> closeIfInsideClick
 *         --> getClose
 *         --> contentStyle
 */
export default class DropDownPanel extends React.Component {
    state = {
        id: (this.props.id ? this.props.id : "dropdown-panel-" + window.generateID()),
        opened: false
    };

    componentDidMount() {
        window.document.body.addEventListener("click", this.onOutsideClick);
        if(this.props.getClose)
            this.props.getClose(this.close);
    }

    componentWillUnmount() {
        window.document.body.removeEventListener("click", this.onOutsideClick);
    }

    onOutsideClick = (e) => {
        if(this.state.opened) {
            if(!this.props.closeIfInsideClick) {
                let element = e.target;
                while(element != null) {
                    if(element.id === this.state.id) {
                        // we clicked on the dropdown
                        return;
                    }
                    element = element.parentElement;
                }
            }
        }
        this.close();
    };

    onHeaderClick = () => {
        this.setState({ opened: !this.state.opened })
    };

    close = () => {
        this.setState({ opened: false });
    };

    render() {
        const contentStyle = {};
        if(this.props.contentStyle) {
            for(var prop in this.props.contentStyle)
                contentStyle[prop] = this.props.contentStyle[prop];
        }
        if(!this.state.opened) {
            contentStyle.display = "none";
        }
        return (
            <div id={this.state.id}
                 className={[styles.dropdownPanel, (this.state.opened ? styles.opened : ""), this.props.className || ""].join(" ")}
                 style={this.props.style}>
                <div className={["dropdown-panel-header", styles.dropdownHeader].join(" ")}
                     onClick={this.onHeaderClick}>
                    <div className={["valign-wrapper z-depth-1 clickable", styles.clickable].join(" ")}>
                        <span className={["valign", styles.dropdownValue].join(" ")}
                              title={this.props.headerTitle}>{this.props.header}</span>
                        <span className={["valign", styles.dropdownCaret].join(" ")}>▼</span>
                    </div>
                </div>
                <div className={
                    ["dropdown-panel-content", styles.dropdownPanelContent,
                        "z-depth-2", this.props.fullWidth ? styles.fullWidth : ''].join(" ")
                } style={contentStyle}>
                    {this.props.content}
                </div>
            </div>
        )
    }
}
