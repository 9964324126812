import React  from 'react';
import _ from "lodash";
import moment from "moment";

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";
import User from "../../model/user";
import generic from "../../model/generic";
import utils from "../../utils";
import Order from "../../model/order";


export default class OrderEmailModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    _attachmentInputRef;

    componentDidMount() {
        window.openOrderEmailModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = function() {}) => {
        let state = {order_id: options.order_id, attachments: [], opened: true, undismissible: options.undismissible, close_cb: close_cb };
        this.setState(state, this.state.openModal);
        setTimeout(this.refresh, 200);
        setTimeout(this.refresh, 500);
        setTimeout(this.refresh, 1000);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onAttachmentInputClick = () => {
        this._attachmentInputRef.click()
    };

    onAttachmentInputChange = () => {
        if(this._attachmentInputRef == null || this._attachmentInputRef.value === "")
            return;
        const file_inputs = this._attachmentInputRef.files;
        if(file_inputs.length > 0) {
            let files = [];
            for(let i = 0; i < file_inputs.length; i++) {
                files.push(file_inputs[i]);
            }
            this._attachmentInputRef.value = "";
            let new_attachments = [], _this = this;
            _.asyncMap(files, (file, cb) => {
                const reader = new FileReader(), name = file.name;
                reader.onload = function(e){
                    let buffer = this.result;
                    new_attachments.push({filename: name, content: utils.arrayBufferToBase64(buffer)});
                    cb();
                };
                reader.readAsArrayBuffer(file);
            }, () => {
                let attachments = _this.state.attachments.slice().concat(new_attachments);
                window.test_attachments = attachments;
                this.setState({attachments: attachments});
            });
        }
    };

    onRemoveAttachment = (index) => {
        let attachments = this.state.attachments.slice();
        attachments.splice(index, 1);
        this.setState({attachments: attachments});
    }

    onSubmit = () => {
        let order_id = this.state.order_id, attachments = this.state.attachments;
        this.state.closeModal();
        window.openOrderDisplayModal({order_id: order_id, download: true, get_data: true, get_data_cb: (name, data) => {
                let order_file = {filename: name, content: utils.arrayBufferToBase64(data)};
                window.test_order_file = order_file;
                Order.sendOrderEmail(order_id, {order_file: order_file, attachments: attachments}, () => {
                    let order = generic.getEntity("order", this.state.order_id),
                        supplier = generic.getEntity("supplier", order.supplier),
                        targets = User.auth_user.email;
                    if(!User.auth_user.test_run){
                        targets += " , " + supplier.email;
                    }
                    order.email_sent_date = moment().format("DD/MM/YYYY");
                    generic.onEntityDataUpdate('order', order.id);
                    window.alertPopup("Information", "Bon de commande envoyé par email : " + targets);
                }, (err) => {
                    window.alertPopup("Erreur", "Erreur lors de l'envoi du bon de commande par email !");
                });
            }});
    };

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let order = generic.getEntity("order", this.state.order_id),
                supplier = generic.getEntity("supplier", order.supplier),
                text = "Valider l'envoi de ce bon de commande par email ? (" + User.auth_user.email + ", " + supplier.email + ")",
                attachments = this.state.attachments, attachments_list = [];
            for(let i = 0; i < attachments.length; i++){
                let attachment = attachments[i];
                attachments_list.push(<li key={attachment.filename}>
                    <span className="bold">{attachment.filename}</span>
                    <i className="material-icons red-text clickable lm" style={{position: 'relative', top: '7px'}} onClick={this.onRemoveAttachment.bind(null, i)}>clear</i>
                </li>);
            }
            content = <div id="order-email-modal-content" className="reglisse">
                <h3 style={{margin: 0}}>{"Envoi e-mail bon de commande N° " + order.order_number}</h3>
                <div style={{marginTop: '50px'}}>
                    <p>{text}</p>
                    {User.auth_user.test_run && <p>{"Mode simulation : l'e-mail ne sera envoyé qu'à l'utilisateur (" + User.auth_user.email + ")"}</p>}
                </div>
                <div style={{marginTop: "30px"}}>
                    <div>
                        <span className={"bold"}>Pièces jointes : </span>
                        <Button color="blue" text={"Ajouter"}
                                className={"lm"} style={{ marginBottom: ".25em", borderRadius: "18px" }}
                                onClick={this.onAttachmentInputClick}/>
                        <input ref={c => this._attachmentInputRef = c} multiple
                               type="file" onChange={this.onAttachmentInputChange} style={{ display: "none" }}/>
                    </div>
                    <ul style={{marginLeft: '40px'}}>
                        {attachments_list}
                    </ul>
                </div>
                <div className="modal-footer">
                    <Button text={'Envoyer'} className="theme-btn" onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
                    <Button text={"Annuler"} color="red" onClick={this.state.closeModal} style={{fontSize: "20px", marginLeft: "15px", borderRadius: "18px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="order-email-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
