import React  from 'react';
import _ from 'lodash';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";
import DataTable from "../global/DataTable";
import ClientDisplay from "../Common/ClientDisplay";

import generic from "../../model/generic";
import User from "../../model/user";
import {jsPDF} from "jspdf";
import config from "../../config";
import domtoimage from 'dom-to-image';

import logo from "../../images/logo.png";

let order_columns = [
    {header: "N° Marché", target: "product.market_number"},
    {header: "Code Produit", target: "product.supplier_ref"},
    {header: "Nom Produit", target: "product.name"},
    {header: "UCD", target: "product.ucd_code"},
    {header: "CIP", target: "product.cip_code"},
    {header: "Colisage", target: "product.packing"},
    {header: "Quantité", target: "order_product.count"},
    {header: "Prix Unit. HT", target: "product.price_ht", suffix: " €"},
    {header: "TVA", target: "product.vat", suffix: ' %'},
    {header: "Prix TTC", compute: computeTTC, suffix: ' €'},
    {header: "Commentaire", target: "order_product.comment"},
];

function computeTTC(data){
    let ht = data.product.price_ht, vat = data.product.vat, quantity = data.order_product.count || 0;
    if(ht !== undefined && vat !== undefined){
        return quantity * _.round(ht * (1 + vat / 100), 2);
    }else{
        return 0;
    }
}

export default class OrderDisplayModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openOrderDisplayModal = (options, close_cb) => {
            if(config.MOBILE_DISPLAY && !options.download){
                window.openMobileOrderDisplayModal(options, close_cb)
            }else{
                this.openModal(options, close_cb);
            }
        };
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = function() {}) => {
        let state = { order_id: options.order_id, download: options.download, opened: true, undismissible: options.undismissible, close_cb: close_cb };
        this.setState(state, () => {
            this.state.openModal();
            if(options.download){
                this.onDownload((name, data) => {
                    setTimeout(() => {
                        this.state.closeModal();
                        setTimeout(() => {
                            if(options.get_data_cb){
                                options.get_data_cb(name, data);
                            }
                        }, 100);
                    }, 100);
                }, options.get_data);
            }
        });
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onDownload = (cb = () => {}, get_data = false) => {
        let element = document.getElementById('order-display');
        element.style.wordSpacing = '10px';
        element.style.width = "1040px";
        element.style.overflow = 'visible';
        let order = generic.getEntity('order', this.state.order_id),
            name = 'BC_hospi_secure_' + User.auth_user.name + "_" + order.order_number + '.pdf';
        let doc = new jsPDF();
        doc.html(
            element,
            {
                html2canvas: {
                    // insert html2canvas options here, e.g.
                    scale: 0.2
                },
                callback: (doc) => {
                    window.test_doc = doc;
                    if(!get_data){
                        doc.save(name + '.pdf');
                    }
                    element.style.wordSpacing = 'initial';
                    element.style.width = "initial";
                    element.style.overflow = 'initial';
                    if(typeof cb === 'function'){
                        if(get_data){
                            return cb(name, doc.output('arraybuffer'));
                        }else{
                            return cb();
                        }
                    }
                }
            });
    };

    getOrderProductDisplay = () => {
        let headers = [], rows = [], total_ttc = 0, order = generic.getEntity('order', this.state.order_id);
        for(let column of order_columns){
            headers.push(column.header);
        }
        for(let order_product of order.products){
            let product = generic.getEntity('product', order_product.id),
                data = {order_product: order_product, product: product}, row = [];
            for(let column of order_columns){
                row.push(_.get(data, column.target, (column.compute && column.compute(data)) || "") + (column.suffix || ''));
            }
            rows.push({data: row});
            total_ttc += computeTTC(data);
        }
        return <div className="col s12">
            <DataTable className="table-border tm" headers={{headers}} rows={rows} />
            <div className="bold tm lm">{"Total TTC : " + total_ttc + " €"}</div>
        </div>
    }

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let order = generic.getEntity('order', this.state.order_id),
                supplier = generic.getEntity('supplier', order.supplier);
            content = <div id="order-display-content" className="row">
                <div id="order-display" style={{padding: '20px 15px', fontSize: '15px'}}>
                    <div className="col s12 center-align" style={{marginBottom: '50px'}}>
                        <div style={{display: 'inline-block', position: 'absolute', left: "40px"}}>
                            <img height={70} src={logo}/>
                            <h5 style={{display: 'inline-block', position: 'relative', bottom: '25px', marginLeft: '10px'}}>Hospi-Secure</h5>
                        </div>
                        <h4 style={{display: "inline-block"}}>{"Bon de commande"}</h4>
                    </div>
                    <div className="col s12">
                        <div style={{display: 'inline-block', width: '50%'}}>
                            <span className="bold">Numéro de commande : </span>
                            <span>{order.order_number}</span>
                        </div>
                        <div style={{textAlign: 'right', display: 'inline-block', width: '50%'}}>
                            <span className="bold">Date de commande : </span>
                            <span>{order.date}</span>
                        </div>
                    </div>
                    <div className="col s12" style={{padding: '10px', border: '1px solid #e0e0e0', marginTop: '5px', marginBottom: '5px'}}>
                        <div className="bold bm">Expéditeur</div>
                        <div className="bold bm">{"Référence client : " + (supplier.client_ref || 'Inconnue')}</div>
                        <ClientDisplay user={User.getCurrentUserData()} forceInline={this.state.download} />
                    </div>
                    <div className="col s12" style={{padding: '10px', border: '1px solid #e0e0e0', marginTop: '5px', marginBottom: '5px'}}>
                        <div className="bold bm">Destinataire</div>
                        <div>{supplier.name}</div>
                        {supplier.address ? <div>
                            <div className="bold">Adresse</div>
                            <div>{supplier.address}</div>
                        </div> : null}
                    </div>
                    <div className="col s12" style={{padding: '20px 10px', border: '1px solid #e0e0e0', marginTop: '20px', marginBottom: '20px', minHeight: '80px'}}>
                        <div className="bold">Commentaires expéditeur</div>
                        <div>{order.comment}</div>
                    </div>
                    {this.getOrderProductDisplay()}
                </div>
                <div className="modal-footer tm">
                    <Button text={'Télécharger'} className='theme-btn' onClick={this.onDownload} disabled={disabled} style={{fontSize: "20px"}} />
                    <Button text={"Fermer"} color="red" onClick={this.state.closeModal} style={{fontSize: "20px", borderRadius: "18px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="order-display-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
