import React from "react";
import _ from 'lodash';

export default class extends React.Component {

    render() {
        let user = this.props.user, show_username = this.props.show_username;
        let containers_classname = this.props.forceInline ? "col l4 tm" : "col s12 l4 tm";
        return (
            <div className="row">
                <div className={containers_classname}>
                    <div className="bold">Client</div>
                    <div>{"Nom : " + user.name}</div>
                    {show_username && <div>{"Username : " + user.username}</div>}
                    <div>{"Email : " + (user.email || "")}</div>
                    <div>{"Téléphone : " + (user.phone || "")}</div>
                    <div>{"Fax : " + (user.fax || "")}</div>
                    <div>{"Siret : " + (user.siret || "")}</div>
                    <div style={{whiteSpace: 'pre'}}>{"Adresse : " + user.address}</div>
                </div>
                {user.billing_contact ? <div className={containers_classname}>
                    <div className="bold">Contact facturation</div>
                    {user.billing_contact.name && <div>{"Nom : " + user.billing_contact.name}</div>}
                    {user.billing_contact.phone && <div>{"Téléphone : " + user.billing_contact.phone}</div>}
                    {user.billing_contact.email && <div>{"Email : " + user.billing_contact.email}</div>}
                    {user.billing_contact.fax && <div>{"Fax : " + user.billing_contact.fax}</div>}
                </div> : null}
                {user.delivery_contact ? <div className={containers_classname}>
                    <div className="bold">Contact livraison</div>
                    {user.delivery_contact.name && <div>{"Nom : " + user.delivery_contact.name}</div>}
                    {user.delivery_contact.phone && <div>{"Téléphone : " + user.delivery_contact.phone}</div>}
                    {user.delivery_contact.email && <div>{"Email : " + user.delivery_contact.email}</div>}
                    {user.delivery_contact.fax && <div>{"Fax : " + user.delivery_contact.fax}</div>}
                </div> : null}
            </div>
        )
    }

}