import React from "react";
import './App.css';
import model from "./model/model";
import ReactTooltip      from "react-tooltip";

import Navbar from "./components/Navbar";
import LoadingLayer from "./components/LoadingLayer";
import AlertPopup from "./components/modal/AlertPopup";
import OrderForm from "./components/modal/OrderForm";
import OrderDisplayModal from "./components/modal/OrderDisplayModal";
import MobileOrderDisplayModal from "./components/modal/MobileOrderDisplayModal";
import OrderEmailModal from "./components/modal/OrderEmailModal";
import ConfirmAction from "./components/modal/ConfirmAction";
import Signin from "./components/Signin";
import MainView from "./components/MainView";

import User from "./model/user";
import config from "./config";

window.onAppViewSelect = null;

export default class extends React.Component {

    state = {
        selectedView: 'signin'
    };

    componentDidMount = () => {

        model.initialize(() => {
            if(User.isAuthenticated()){
                this.onViewSelect('main');
            }
        });

        setInterval(() => {
            try {
                ReactTooltip.rebuild();
            } catch(e) {
                console.error("Tooltip rebuild error")
            }
        }, 1000);
        window.refreshApp = this.refresh;
        window.onAppViewSelect = this.onViewSelect;
    };

    onViewSelect = (view) => {
        if(view !== this.state.selectedView){
            this.setState({selectedView: view});
        }
    };

    onSigninSuccess = () => {
        this.setState({selectedView: 'main'});
    };

    refresh = () => {
        this.forceUpdate();
    };

    render() {
        let selected = this.state.selectedView;
        return (
            <div id="app-container" className={config.MOBILE_DISPLAY ? "mobile-display" : ""}>
                <Navbar selectedView={selected} onViewSelect={this.onViewSelect} show={selected !== 'signin'} />
                <Signin show={selected === 'signin'} onSigninSuccess={this.onSigninSuccess} />
                <MainView show={selected === 'main'} />
                <ReactTooltip id={"application-tooltip"} className={"tool-tip z-depth-1"} effect={"solid"} backgroundColor="white" textColor="black"/>
                <LoadingLayer  />
                <AlertPopup/>
                <OrderForm/>
                <OrderDisplayModal/>
                <MobileOrderDisplayModal/>
                <ConfirmAction />
                <OrderEmailModal />
            </div>
        )
    }
}