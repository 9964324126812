import React from "react";
import _ from 'lodash';

import utils from "../../utils";
import Supplier from "../../model/supplier";
import Product from "../../model/product";
import User from "../../model/user";
import Rescue_plan from "../../model/rescue_plan";


import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";
import ClientDisplay from "../Common/ClientDisplay";

export default class extends React.Component {

    state = {supplier_map: null, suppliers: [], supplier_errors: [], test_run: true,
        product_map: null, products: [], product_errors: [], user: null, user_errors: [], user_password: ""};

    _supplierMapInputRef;
    _supplierInputRef;
    _productMapInputRef;
    _productInputRef;
    _userInputRef;

    onSupplierMapFileInputClick = () => {
        this._supplierMapInputRef.click()
    };

    onSupplierFileInputClick = () => {
        this._supplierInputRef.click()
    };

    onProductMapFileInputClick = () => {
        this._productMapInputRef.click()
    };

    onProductFileInputClick = () => {
        this._productInputRef.click()
    };

    onUserFileInputClick = () => {
        this._userInputRef.click()
    };

    onSupplierMapInputChange = () => {
        if(this._supplierMapInputRef == null || this._supplierMapInputRef.value === "")
            return;
        const files = this._supplierMapInputRef.files;
        if(files.length > 0) {
            const file = files[0];
            if(['csv', 'xls', 'xlsx'].indexOf(utils.getFileExtension(file)) > -1){
                utils.parseXlsxFile(file, (json) => {
                    this.setState({supplier_map: json[0]});
                    this._supplierMapInputRef.value = "";
                });
            }else{
                this._supplierMapInputRef.value = "";
            }
        }
    };

    onSupplierInputChange = () => {
        if(this._supplierInputRef == null || this._supplierInputRef.value === "")
            return;
        const files = this._supplierInputRef.files;
        if(files.length > 0) {
            const file = files[0];
            if(['csv', 'xls', 'xlsx'].indexOf(utils.getFileExtension(file)) > -1){
                utils.parseXlsxFile(file, (json) => {
                    this._supplierInputRef.value = "";
                    const {items, errors} = Supplier.formatSupplierData(json, this.state.supplier_map);
                    this.setState({suppliers: items, supplier_errors: errors});
                });
            }else{
                this._supplierInputRef.value = "";
            }
        }
    };

    onProductMapInputChange = () => {
        if(this._productMapInputRef == null || this._productMapInputRef.value === "")
            return;
        const files = this._productMapInputRef.files;
        if(files.length > 0) {
            const file = files[0];
            if(['csv', 'xls', 'xlsx'].indexOf(utils.getFileExtension(file)) > -1){
                utils.parseXlsxFile(file, (json) => {
                    this.setState({product_map: json[0]});
                    this._productMapInputRef.value = "";
                });
            }else{
                this._productMapInputRef.value = "";
            }
        }
    }

    onProductInputChange = () => {
        if(this._productInputRef == null || this._productInputRef.value === "")
            return;
        const files = this._productInputRef.files;
        if(files.length > 0) {
            const file = files[0];
            if(['csv', 'xls', 'xlsx'].indexOf(utils.getFileExtension(file)) > -1){
                utils.parseXlsxFile(file, (json) => {
                    this._productInputRef.value = "";
                    const {items, errors} = Product.formatProductData(json, this.state.product_map);
                    this.setState({products: items, product_errors: errors});
                });
            }else{
                this._productInputRef.value = "";
            }
        }
    };

    onUserInputChange = () => {
        if(this._userInputRef == null || this._userInputRef.value === "")
            return;
        const files = this._userInputRef.files;
        if(files.length > 0) {
            const file = files[0];
            if(['csv', 'xls', 'xlsx'].indexOf(utils.getFileExtension(file)) > -1){
                utils.parseXlsxFile(file, (json) => {
                    const {items, errors} = User.formatUserData(json);
                    if(items.length > 0){
                        this.setState({user: items[0], user_errors: errors});
                    }else{
                        this._userInputRef.value = "";
                        this.setState({user: null, user_errors: errors});
                    }
                });
            }else{
                this._userInputRef.value = "";
            }
        }
    };

    onUserPasswordChange = (value) => {
        this.setState({user_password: value});
    };

    onTestRunClick = () => {
        this.setState({test_run: !this.state.test_run});
    };

    onSubmit = () => {
        let user = _.cloneDeep(this.state.user);
        user.password = this.state.user_password;
        Rescue_plan.createRescuePlan(user, this.state.suppliers, this.state.products, this.state.test_run, (err) => {
           if(err){
               window.alertPopup('Erreur', 'Une erreur est survenue lors de la création du plan de sauvegarde !');
           }else{
               this._productInputRef.value = "";
                this._supplierInputRef.value = "";
                this._userInputRef.value = "";
               this.setState({supplier_map: null, suppliers: [], supplier_errors: [], test_run: true, product_map: null, products: [], product_errors: [], user: null, user_errors: [], user_password: ""});
           }
        });
    };

    render() {
        let suppliers = this.state.suppliers, supplier_errors = this.state.supplier_errors,
            products = this.state.products, product_errors = this.state.product_errors,
            user = this.state.user, user_errors = this.state.user_errors, password = this.state.user_password;
        let submit_disabled = suppliers.length === 0 || supplier_errors.length > 0 ||
            products.length === 0 || product_errors.length > 0 ||
            user === null || user_errors.length > 0 || password === "";
        return (
            <div>
                <h4>Création plan de sauvegarde</h4>
                <div style={{borderTop: '2px solid #e0e0e0'}}>
                    <div>
                        <h5 style={{display: 'inline-block'}}>Client ciblé</h5>
                        <Button color="blue" text={"Import"}
                                className={"lm"} style={{ marginBottom: ".25em" }}
                                onClick={this.onUserFileInputClick}/>
                        <input ref={c => this._userInputRef = c}
                               type="file" onChange={this.onUserInputChange} style={{ display: "none" }}/>
                    </div>
                    <div>
                        {!user && <p>Aucun client importé</p>}
                        {user && <div style={{textAlign: 'left'}}>
                            <ClientDisplay user={user} show_username={true} />
                            {user.delivery_points ? <p>
                                <div className="bold">Points de livraison</div>
                                {user.delivery_points.map((point) => {
                                    return <div>{point}</div>
                                })}
                            </p> : null}
                            <p><InputField label={"Mot de passe"} style={{display: 'inline-block', width: '200px'}}
                                           value={this.state.user_password} onChange={this.onUserPasswordChange} /></p>
                        </div>}
                        {user_errors.length > 0 && <div>
                            <p className="red-text">Le client n'a pas pu être importé:</p>
                            <ul>
                                {user_errors.map((error, i) => <li style={{borderTop: '1px solid #e0e0e0'}} key={i}>
                                    <p>{JSON.stringify(error.data)}</p>
                                    <p>{JSON.stringify(error.error_list)}</p>
                                </li>)}
                            </ul>
                        </div>}
                    </div>
                </div>
                <div style={{borderTop: '2px solid #e0e0e0'}}>
                    <div>
                        <h5 style={{display: 'inline-block'}}>Fournisseurs</h5>
                        <Button color="blue" text={"Import Map"}
                                className={"lm"} style={{ marginBottom: ".25em" }}
                                onClick={this.onSupplierMapFileInputClick}/>
                        <input ref={c => this._supplierMapInputRef = c}
                               type="file" onChange={this.onSupplierMapInputChange} style={{ display: "none" }}/>
                        <Button color="blue" text={"Import"}
                                className={"lm"} style={{ marginBottom: ".25em" }}
                                onClick={this.onSupplierFileInputClick} disabled={!this.state.supplier_map} />
                        <input ref={c => this._supplierInputRef = c}
                               type="file" onChange={this.onSupplierInputChange} style={{ display: "none" }}/>
                    </div>
                    <div>
                        {suppliers.length === 0 && supplier_errors.length === 0 && <p>Aucun fournisseur importé</p>}
                        {suppliers.length > 0 && <div>
                            <p className="green-text">{suppliers.length} fournisseurs importés</p>
                        </div>}
                        {supplier_errors.length > 0 && <div>
                            <p className="red-text">Les fournisseurs suivants n'ont pas pu être importés:</p>
                            <ul>
                                {supplier_errors.map((error, i) => <li style={{borderTop: '1px solid #e0e0e0'}} key={i}>
                                    <p>{JSON.stringify(error.data)}</p>
                                    <p>{JSON.stringify(error.error_list)}</p>
                                </li>)}
                            </ul>
                        </div>}
                    </div>
                </div>
                <div style={{borderTop: '2px solid #e0e0e0'}}>
                    <div>
                        <h5 style={{display: 'inline-block'}}>Produits</h5>
                        <Button color="blue" text={"Import Map"}
                                className={"lm"} style={{ marginBottom: ".25em" }}
                                onClick={this.onProductMapFileInputClick}/>
                        <input ref={c => this._productMapInputRef = c}
                                 type="file" onChange={this.onProductMapInputChange} style={{ display: "none" }}/>
                        <Button color="blue" text={"Import"}
                                className={"lm"} style={{ marginBottom: ".25em" }}
                                onClick={this.onProductFileInputClick} disabled={!this.state.product_map} />
                        <input ref={c => this._productInputRef = c}
                               type="file" onChange={this.onProductInputChange} style={{ display: "none" }}/>
                    </div>
                    <div>
                        {products.length === 0 && product_errors.length === 0 && <p>Aucun produit importé</p>}
                        {products.length > 0 && <div>
                            <p className="green-text">{products.length} produits importés</p>
                        </div>}
                        {product_errors.length > 0 && <div>
                            <p className="red-text">Les produits suivants n'ont pas pu être importés:</p>
                            <ul>
                                {product_errors.map((error, i) => <li style={{borderTop: '1px solid #e0e0e0'}} key={i}>
                                    <p>{JSON.stringify(error.data)}</p>
                                    <p>{JSON.stringify(error.error_list)}</p>
                                </li>)}
                            </ul>
                        </div>}
                    </div>
                </div>
                <div className="center-align tm">
                    <CheckBox label="Simulation" checked={this.state.test_run} onClick={this.onTestRunClick} />
                </div>
                <div className="center-align tm">
                    <Button text="Créer plan" color="green" disabled={submit_disabled} onClick={this.onSubmit} />
                </div>
            </div>
        )
    }

}