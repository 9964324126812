import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";
import DataTable from "../global/DataTable";
import ClientDisplay from "../Common/ClientDisplay";

import generic from "../../model/generic";
import User from "../../model/user";
import {jsPDF} from "jspdf";
import domtoimage from 'dom-to-image';

import logo from "../../images/logo.png";
import _ from "lodash";

let order_columns = [
    {header: "Code Prd", target: "product.supplier_ref"},
    {header: "Nom", target: "product.name"},
    {header: "Qté", target: "order_product.count"},
    {header: "TTC", compute: computeTTC, suffix: ' €'},
    {header: "Comm.", target: "order_product.comment"},
];

function computeTTC(data){
    let ht = data.product.price_ht, vat = data.product.vat, quantity = data.order_product.count || 0;
    if(ht !== undefined && vat !== undefined){
        return quantity * _.round(ht * (1 + vat / 100), 2);
    }else{
        return 0;
    }
}

export default class MobileOrderDisplayModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openMobileOrderDisplayModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = function() {}) => {
        let state = { order_id: options.order_id, opened: true, undismissible: options.undismissible, close_cb: close_cb };
        this.setState(state, () => {
            this.state.openModal();
            if(options.download){
                this.onDownload(this.state.closeModal);
            }
        });
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onDownload = (cb = () => {}) => {
        let element = document.getElementById('order-display');
        element.style.wordSpacing = '10px';
        let order = generic.getEntity('order', this.state.order_id),
            name = 'BC_hospi_secure_' + order.order_number + '.pdf';
        let doc = new jsPDF();
        doc.html(
            element,
            {
                html2canvas: {
                    // insert html2canvas options here, e.g.
                    scale: 0.2
                },
                callback: (doc) => {
                    doc.save(name + '.pdf');
                    element.style.wordSpacing = 'initial';
                    cb();
                }
            });
    };

    getOrderProductDisplay = () => {
        let headers = [], rows = [], total_ttc = 0, order = generic.getEntity('order', this.state.order_id);
        for(let column of order_columns){
            headers.push(column.header);
        }
        for(let order_product of order.products){
            let product = generic.getEntity('product', order_product.id),
                data = {order_product: order_product, product: product}, row = [];
            for(let column of order_columns){
                row.push(_.get(data, column.target, (column.compute && column.compute(data)) || "") + (column.suffix || ''));
            }
            rows.push({data: row});
            total_ttc += computeTTC(data);
        }
        return <div className="col s12">
            <DataTable className="table-border tm" headers={{headers}} rows={rows} />
            <div className="bold tm lm">{"Total TTC : " + total_ttc + " €"}</div>
        </div>
    }

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let order = generic.getEntity('order', this.state.order_id),
                supplier = generic.getEntity('supplier', order.supplier),
                user = User.getCurrentUserData(), product_headers = ['Code produit', 'Nom', 'Qté'],
                product_rows = [];
            for(let order_product of order.products){
                let product = generic.getEntity('product', order_product.id);
                product_rows.push({data: [product.supplier_ref, product.name, order_product.count]});
            }
            content = <div id="mobile-order-display-content" className="row">
                <div id="order-display" style={{fontSize: '14px'}}>
                    <div className="col s12 " style={{marginBottom: '10px'}}>
                        <div style={{display: 'inline-block', width: '70px'}}>
                            <img height={60} src={logo}/>
                        </div>
                        <div style={{display: 'inline-block'}}>
                            <h5 style={{marginLeft: '10px', fontSize: '20px'}}>Hospi-Secure</h5>
                            <h5 style={{fontSize: '20px', marginTop: 0}}>{"Bon de commande"}</h5>
                        </div>
                    </div>
                    <div className="col s12">
                        <span className="bold">N° de commande : </span>
                        <span>{order.order_number}</span>
                    </div>
                    <div className="col s12 tm">
                        <span className="bold">Date de commande : </span>
                        <span>{order.date}</span>
                    </div>
                    <div className="col s12" style={{padding: '10px', border: '1px solid #e0e0e0', marginTop: '5px', marginBottom: '5px'}}>
                        <div className="bold bm">Expéditeur</div>
                        <div className="bold bm">{"Référence client : " + (supplier.client_ref || 'Inconnue')}</div>
                        <ClientDisplay user={User.getCurrentUserData()} />
                    </div>
                    <div className="col s12" style={{padding: '10px', border: '1px solid #e0e0e0', marginTop: '5px', marginBottom: '5px'}}>
                        <div className="bold bm">Destinataire</div>
                        <div>{supplier.name}</div>
                        {supplier.address ? <div>
                            <div className="bold">Adresse</div>
                            <div>{supplier.address}</div>
                        </div> : null}
                    </div>
                    <div className="col s12" style={{padding: '10px', border: '1px solid #e0e0e0', marginTop: '5px', marginBottom: '5px', minHeight: '80px'}}>
                        <div className="bold">Commentaires expéditeur</div>
                        <div>{order.comment}</div>
                    </div>
                    {this.getOrderProductDisplay()}
                </div>
                <div className="modal-footer tm">
                    <Button text={'Télécharger'} className='theme-btn' onClick={this.onDownload} disabled={disabled} style={{fontSize: "20px", marginRight: '5px'}} />
                    <Button text={"Fermer"} color="red" onClick={this.state.closeModal} style={{fontSize: "20px", borderRadius: "18px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="mobile-order-display-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
