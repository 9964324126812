import React from "react";

import AgGrid from "../global/AgGrid";

import generic from "../../model/generic";

export default class extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state && this.state.gridApi){
            this.state.gridColumnApi.autoSizeAllColumns();
            setTimeout(this.state.gridApi.sizeColumnsToFit, 200);
        }
    }

    onGridReady = (event) => {
        this.setState({ gridApi: event.api, gridColumnApi: event.columnApi });
    };

    getHeaders = () => {
        let headers = [
            {headerName: "Code produit", field: "ref"},
            {headerName: "Code fournisseur", field: "supplier_ref"},
            {headerName: "Libellé", field: "name", filter: 'agTextColumnFilter', floatingFilter: true},
            {headerName: "Fournisseur", field: "supplier", filter: 'agTextColumnFilter', floatingFilter: true},
            {headerName: "Colisage", field: "packing"},
            {headerName: "N° Marché", field: "market_number"},
            {headerName: "Prix HT", field: "price_ht"},
            {headerName: "Prix TTC", field: "price_ttc"},
            {headerName: "TVA", field: "vat"},
            {headerName: "UCD", field: "ucd_code"},
            {headerName: "CIP", field: "cip_code"}
        ];
        return headers;
    };

    row_cache = null;

    getRows = () => {
        if(this.row_cache && this.row_cache.length > 0){
            return this.row_cache;
        }
        let products = generic.getEntities("product", true), rows = [];
        for(let product of products){
            let supplier = generic.getEntityFromProperty("supplier", "ref", product.supplier);
            let row = {
                id: product.id,
                ref: product.ref,
                supplier_ref: product.supplier_ref,
                name: product.name,
                supplier: (supplier && supplier.name) || 'Inconnu',
                price_ht: product.price_ht || "",
                price_ttc: product.price_ttc || "",
                vat: product.vat || "",
                ucd_code: product.ucd_code || "",
                cip_code: product.cip_code || ""
            }
            rows.push(row);
        }
        this.row_cache = rows;
        return rows;
    };

    render(){

        return <div id="user-product-view" >
            <div style={{height: "500px", textAlign: 'left'}}>
                <AgGrid id={"users"}
                        columnDefs={this.getHeaders()}
                        rowData={this.getRows()} immutableData={true} getRowNodeId={row => row.id}
                        gridOptions={{
                            /*rowSelection: "single",
                            getRowClass: this.getRowClass,
                            onRowSelected: this.onRowSelectionChange,*/
                            onGridReady: this.onGridReady
                        }}/>
            </div>
        </div>
    }

}